import { useState } from "react";
import ReCAPTCHA from 'react-google-recaptcha';
import useRecaptcha from './recaptchav2';
import emailjs from "emailjs-com";
import React from "react";
import ScrollAnimation from 'react-animate-on-scroll';

const initialState = {
  name: "",
  email: "",
  message: ""
};
export const Contact = (props) => {
  const { capchaToken, recaptchaRef, handleRecaptcha } = useRecaptcha();
  const [{ name, email, message, enviado }, setState] = useState( { ...{ enviado: false }, ...initialState });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (enviado) {
      return;
    }
    if (!capchaToken) {
      alert('Por favor completa el captcha');
      return;
    }
    if (capchaToken && name && email && message) {
      emailjs
        .sendForm(props.data ? props.data.email_js_service : "service_id", props.data ? props.data.email_js_template : "template_id", e.target, props.data ? props.data.email_js_pub_key : "emailjs_pub_key")
        .then(
          (result) => {
            recaptchaRef.current?.reset();
            setState( { ...{ enviado: true }, ...initialState });
          },
          (error) => {
            recaptchaRef.current?.reset();
          }
        );
    } else {
      alert('Faltan datos en el formulario');
      recaptchaRef.current?.reset();
    }
  };
  return (
    <div>
      <div id="contact">
        <div className="container">
          <ScrollAnimation offset={900} animateIn="backInRight" animateOnce={true}>
            <div className="col-sm-12 col-md-5 col-md-push-7">
              {!enviado ?
                <div className="section-title">
                  <h2>Deje su consulta</h2>
                </div>
                :
                <div className="row">
                  <br></br>
                  <br></br>
                  <h3>Gracias por tu consulta!</h3>
                </div>
              }
              {!enviado &&
                <form name="sentMessage" validate onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                          placeholder="Nombre"
                          required
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Email"
                          required
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder="Mensaje"
                      required
                      onChange={handleChange}
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  {props.data && 
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={props.data.recaptcha_pub_key}
                      onChange={handleRecaptcha}
                    />
                  }
                  <div id="success"></div>
                  <button type="submit" className="btn btn-custom btn-lg">
                    Enviar
                  </button>
                </form>
              }
            </div>
          </ScrollAnimation>
          <ScrollAnimation offset={900}   animateIn="backInLeft" animateOnce={true} >
            <div className="col-sm-12 col-md-7 col-md-pull-5 contact-info">
            <div className="section-title">
                <h2>Información de contacto</h2>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span>{" "}
                  {props.data ? props.data.email : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Teléfono
                  </span>{" "}
                  {props.data ? props.data.phone : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <img src="img/instagram.png" loading="lazy" alt="instagram" title="instagram"></img> Instagram
                  </span>{" "}
                  <a href={props.data ? props.data.instagram : "loading"} target="_blank">{props.data ? props.data.instagramUser : "loading"}</a> 
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> Dirección
                  </span>
                  {props.data ? props.data.address : "loading"}
                </p>
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation offset={900} animateIn="backInUp" animateOnce={true} >
            <div className="col-md-12">
              <div>
                <iframe title="Ubicación" className="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6602.281478717832!2d-58.9621877!3d-34.1683192!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bb7233c680f923%3A0xa9ba37dae55f5ef9!2sRawson%20629%2C%20Campana%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1ses-419!2sar!4v1731178519313!5m2!1ses-419!2sar" width="600" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
             {new Date().getFullYear()} | vSQUAD
          </p>
        </div>
      </div>
    </div>
  );
};
