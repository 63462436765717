import React from "react";
import ScrollAnimation from 'react-animate-on-scroll';

export const About2 = (props) => {
  return (
    <div id="about2">
      <div className="container">
        <ScrollAnimation offset={900} animateIn="fadeInUp" animateOnce={true} duration={0.5}>
          <div className="row">
            <div className="col-xs-12">
              <h2>NUESTROS PRINCIPIOS RECTORES</h2>
            </div>
          </div>
        </ScrollAnimation>
        <ScrollAnimation offset={900} animateIn="backInLeft" animateOnce={true}>
          <div className="row">
            <div className="col-xs-12 col-md-6">
              {" "}
              <img src="img/features-1.webp" loading="lazy" className="img-responsive" alt="GT Laboral" title="GT Laboral" width="740" height="492"/>{" "}
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <div className="list-style">
                  <div className="col-xs-12">
                    <ul>
                      {props.data
                        ? props.data.List1.map((d, i) => (
                            <li key={`${i}`}><strong>{d.label}: </strong>{d.text}</li>
                          ))
                        : "loading"}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ScrollAnimation>
        <ScrollAnimation offset={900} animateIn="backInRight" animateOnce={true}>
          <div className="row">
            <div className="col-xs-12 col-md-6 col-md-push-6">
              {" "}
              <img src="img/features-2.webp" loading="lazy" className="img-responsive" alt="Un equipo, dedicado a brindar un servicio integral" title="Un equipo, dedicado a brindar un servicio integral" width="740" height="492"/>{" "}
            </div>
            <div className="col-xs-12 col-md-6 col-md-pull-6">
              <div className="about-text">
                <div className="list-style">
                  <div className="col-xs-12">
                    <ul>
                      {props.data
                        ? props.data.List2.map((d, i) => (
                            <li key={`${i}`}><strong>{d.label}: </strong>{d.text}</li>
                          ))
                        : "loading"}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
};
