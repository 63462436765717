import React from "react";
import ScrollAnimation from 'react-animate-on-scroll';

export const Image = ({ title, largeImage, smallImage }) => {
  return (
    <div className="portfolio-item">
      <div className="hover-bg">
        {" "}
        <ScrollAnimation offset={900} animateIn="fadeInDown" animateOnce={true} >
          <a href={largeImage} title={title} data-lightbox-gallery="gallery1" target="_blank">
            <div className="hover-text">
              <h4>{title}</h4>
            </div>
            <img src={smallImage} className="img-responsive img-gallery" alt="Articulo" title={title} loading="lazy" width="512" height="512"/>{" "}
          </a>{" "}
        </ScrollAnimation>
      </div>
    </div>
  );
};
