import React from "react";
import { Modal } from "./modal";
export const Talles = (props) => {

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
      setOpen(false);
  };

  const handleOpen = () => {
      setOpen(true);
  };

  return (
    <div id="talles">
      <div className="center-button" onClick={handleOpen}>
        <div className="btn btn-custom btn-lg">
          Tabla de talles
        </div>
      </div>
      <Modal isOpen={open} onClose={handleClose} header={"Nuestros talles"}>
          <div className="row">
            <div className="col-xs-12 col-md-12">
              <div className="panel panel-default">
                <div className="panel-heading">
                  <h3 className="panel-title">Información importante</h3>
                </div>
                <div className="panel-body">
                  <ol>
                    <li>Todas las medidas están expresadas en centímetros</li>
                    <li>Para el caso de talles de camperas de abrigo, debe considerarse 1 talle más que la camisa o mameluco que la persona utilice</li>
                    <li>Para el caso de talles que no se correspondan a estas tablas, los mismos se consideran especiales y se deben confeccionar a medida</li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-md-6">
              <h4>
                CURVA DE TALLES DE MAMELUCO (MC-MR)
              </h4>
              <img src="img/talles/mameluco.jpg" loading="lazy" className="img-responsive img-talles" alt="Talles de mameluco" title="Talles de mameluco"/>{" "}
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <div className="list-style">
                  <div className="col-xs-12">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th className="text-center">Medida</th>
                          <th className="text-center">A (ESPALDA)</th>
                          <th className="text-center">B (CINTURA)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-center">XS</td>
                          <td className="text-center">47</td>
                          <td className="text-center">42</td>
                        </tr>
                        <tr>
                          <td className="text-center">S</td>
                          <td className="text-center">48</td>
                          <td className="text-center">46</td>
                        </tr>
                        <tr>
                          <td className="text-center">M</td>
                          <td className="text-center">49</td>
                          <td className="text-center">50</td>
                        </tr>
                        <tr>
                          <td className="text-center">L</td>
                          <td className="text-center">50</td>
                          <td className="text-center">54</td>
                        </tr>
                        <tr>
                          <td className="text-center">XL</td>
                          <td className="text-center">52</td>
                          <td className="text-center">58</td>
                        </tr>
                        <tr>
                          <td className="text-center">XXL</td>
                          <td className="text-center">54</td>
                          <td className="text-center">62</td>
                        </tr>
                        <tr>
                          <td className="text-center">XXXL</td>
                          <td className="text-center">56</td>
                          <td className="text-center">66</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 col-md-push-6">
              <h4>
                CURVA DE TALLES DE CAMISA (CC-CR)
              </h4>
              <img src="img/talles/camisa.jpg" loading="lazy" className="img-responsive img-talles" alt="Talles de camisa" title="Talles de camisa"/>{" "}
            </div>
            <div className="col-xs-12 col-md-6 col-md-pull-6">
              <div className="about-text">
                <div className="list-style">
                  <div className="col-xs-12">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th className="text-center">Talle</th>
                          <th className="text-center">A (CUELLO)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-center">38</td>
                          <td className="text-center">38</td>
                        </tr>
                        <tr>
                          <td className="text-center">40</td>
                          <td className="text-center">40</td>
                        </tr>
                        <tr>
                          <td className="text-center">44</td>
                          <td className="text-center">44</td>
                        </tr>
                        <tr>
                          <td className="text-center">46</td>
                          <td className="text-center">46</td>
                        </tr>
                        <tr>
                          <td className="text-center">48</td>
                          <td className="text-center">48</td>
                        </tr>
                        <tr>
                          <td className="text-center">50</td>
                          <td className="text-center">50</td>
                        </tr>
                        <tr>
                          <td className="text-center">54</td>
                          <td className="text-center">54</td>
                        </tr>
                        <tr>
                          <td className="text-center">56</td>
                          <td className="text-center">56</td>
                        </tr>
                        <tr>
                          <td className="text-center">58</td>
                          <td className="text-center">58</td>
                        </tr>
                        <tr>
                          <td className="text-center">60</td>
                          <td className="text-center">60</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <h4>
                CURVA DE TALLES DE PANTALÓN (PC-PR-PCC-PCR)
              </h4>
              <img src="img/talles/jean.jpg" loading="lazy" className="img-responsive img-talles" alt="Talles de pantalon" title="Talles de camisa"/>{" "}
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <div className="list-style">
                  <div className="col-xs-12">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th className="text-center">Talle</th>
                          <th className="text-center">B (CINTURA)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-center">38</td>
                          <td className="text-center">38</td>
                        </tr>
                        <tr>
                          <td className="text-center">40</td>
                          <td className="text-center">40</td>
                        </tr>
                        <tr>
                          <td className="text-center">44</td>
                          <td className="text-center">44</td>
                        </tr>
                        <tr>
                          <td className="text-center">46</td>
                          <td className="text-center">46</td>
                        </tr>
                        <tr>
                          <td className="text-center">48</td>
                          <td className="text-center">48</td>
                        </tr>
                        <tr>
                          <td className="text-center">50</td>
                          <td className="text-center">50</td>
                        </tr>
                        <tr>
                          <td className="text-center">54</td>
                          <td className="text-center">54</td>
                        </tr>
                        <tr>
                          <td className="text-center">56</td>
                          <td className="text-center">56</td>
                        </tr>
                        <tr>
                          <td className="text-center">58</td>
                          <td className="text-center">58</td>
                        </tr>
                        <tr>
                          <td className="text-center">60</td>
                          <td className="text-center">60</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </Modal>
    </div>
  );
};
