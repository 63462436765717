import React from "react";
import ScrollAnimation from 'react-animate-on-scroll';

export const Features = (props) => {
  return (
    <div id="features" className="text-center p-15">
      <div className="container">
        <ScrollAnimation offset={900} animateIn="lightSpeedInRight" animateOnce={true} >
          <div className="row">
            {props.data
              ? props.data.map((d, i) => (
                  <div key={`${d.title}-${i}`} className="col-xs-12 col-md-4">
                    {" "}
                    <img src={`img/features/${i+1}.webp`} loading="lazy" className="img-responsive img-features" width="100" height="100" alt={d.title} title={d.title}/>
                    <h3>{d.title}</h3>
                    <p>{d.text}</p>
                  </div>
                ))
              : "Loading..."}
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
};
