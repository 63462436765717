import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About1 } from "./components/about-1";
import { About2 } from "./components/about-2";
import { Gallery } from "./components/gallery";
import { Contact } from "./components/contact";
import { FabWhatsapp } from "./components/fab-whatsapp";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import "animate.css/animate.compat.css"

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
  offset: 80
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div style={{overflowX:'hidden'}}>
      <Navigation />
      <Header data={landingPageData.Header} />
      <About1 data={landingPageData.About1} />
      <About2 data={landingPageData.About2} />
      <Features data={landingPageData.Features} />
      <Gallery data={landingPageData.Gallery} />
      <Contact data={landingPageData.Contact} />
      <FabWhatsapp data={landingPageData.Contact} />
    </div>
  );
};

export default App;
