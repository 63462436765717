import { Image } from "./image";
import { Talles } from "./talles";
import ScrollAnimation from 'react-animate-on-scroll';
import React from "react";

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <ScrollAnimation offset={900} animateIn="fadeInUp" animateOnce={true} >
          <div className="section-title">
            <h2>{props.data ? props.data.header : ""}</h2>
            <p>{props.data ? props.data.subheader : ""}</p>
          </div>
        </ScrollAnimation>
        <Talles/>
        <div className="row">
            <div className="portfolio-items">
              {props.data
                ? props.data.items.map((d, i) => (
                      <div key={`${d.title}-${i}`} className="col-sm-6 col-md-4 col-lg-4">
                        <Image
                          title={d.title}
                          largeImage={d.largeImage}
                          smallImage={d.smallImage}
                        />
                      </div>
                  ))
                : "Loading..."}
            </div>
        </div>
      </div>
    </div>
  );
};
