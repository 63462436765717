import React from "react";
import ScrollAnimation from 'react-animate-on-scroll';

export const About1 = (props) => {
  return (
    <div id="about1" className="text-center p-15">
      <div className="container">
        <ScrollAnimation offset={900} animateIn="fadeInUp" animateOnce={true} >
          <div className="row">
            {props.data
              ? props.data.map((d, i) => (
                    <div key={`${d.title}-${i}`} className="col-xs-12 col-md-4">
                      {" "}
                      <i className={d.icon}></i>
                      <h3>{d.title}</h3>
                      <p>{d.text}</p>
                    </div>
                ))
              : "Loading..."}
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
};
