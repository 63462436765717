import React from "react";

export const FabWhatsapp = (props) => {

  const handleOpen = () => {
    if (props.data && typeof window != "undefined") {
      window.open(props.data.apiWhatsapp, '_blank');
    }
  }

  return (
    <div onClick={() => {handleOpen()}} className="float">
      <i className="fa fa-whatsapp my-float"></i>
    </div>
  );
};

