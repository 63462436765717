import React from "react";
import ScrollAnimation from 'react-animate-on-scroll';

export const Modal = ({ isOpen, onClose, children, header }) => {
    if (!isOpen) return null;

    const stopPropagation= (e) => {
        e.stopPropagation();  //  <------ Here is the magic
    }

    return (
        <div
            onClick={onClose}
            className="modal"
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <ScrollAnimation offset={900} scrollableParentSelector={'.modal'} animateIn="fadeInUp" animateOnce={true} duration={0.5}>
                <div className="modal-dialog modal-lg modal-dialog-scrollable" tabIndex="-1" role="document" style={{zIndex: 1000}}> 
                    <div className="modal-content" onClick={stopPropagation}> 
                        <div className="modal-header"> 
                            <button type="button" className="close close-big" onClick={onClose} aria-label="Close"><span aria-hidden="true">×</span></button> 
                            <h2>{header ? header : "Loading"}</h2>
                        </div> 
                        <div className="modal-body" style={{height: '80vh', overflowY: 'scroll'}}> {children} </div> 
                    </div> 
                </div> 
            </ScrollAnimation>
            
        </div>
    );
};